import { Button, DatePicker, Form, Input, Modal, Select } from 'antd';
import React, { useEffect, useState } from 'react';
// import { CheckOutlined } from '@ant-design/icons';
// import Icon from "../../../../../Components/Icon";
import dayjs from 'dayjs';
import moment from 'moment';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { BASE_URL } from '../../../../../Consts/variables';
import useUniversalFetch from '../../../../../Hooks/useApi';

const { TextArea } = Input;

const KanbanModal = ({
  expense,
  isVisible,
  onSave,
  onCancel,
  isEditMode,
  isLoading,
  isSuccess,
}) => {
  const [form] = Form.useForm();
  // const [disableRate, setDisableRate] = useState(false);
  const [canban_status, setCanban_status] = useState('');

  const token = typeof window !== 'undefined' && JSON.parse(localStorage.getItem('authToken'));
  const { useFetchQuery } = useUniversalFetch();

  const {
    data: currencyData,
    isLoading: isCurrencyLoading,
    // error: currencyError,
    // isError: isCurrencyError,
  } = useFetchQuery({
    queryKey: 'currency',
    token,
    url: `${BASE_URL}setting/currency`,
  });

  const {
    data: canbanStatusData,
    // isLoading: iscanbanStatusLoading,
    // error: canbanStatusError,
    // isError: iscanbanStatusError,
  } = useFetchQuery({
    queryKey: 'canban-status',
    token,
    url: `${BASE_URL}setting/canban-status`,
    id: `?is_active=true`,
  });

  const currencies = currencyData?.status === 200 ? currencyData.data : [];
  const canbanStatus = canbanStatusData?.status === 200 ? canbanStatusData.data : [];

  const {
    data: salesData,
    // isLoading: isSalesDataLoading,
    // error: salesDataError,
    // isError: isSalesDataError,
    // isSuccess: isSuccessalesData,
  } = useFetchQuery({
    queryKey: 'sales',
    token: token,
    url: `${BASE_URL}employee/find-by-role`,
    id: `?role=${'sales'}`,
  });

  const sales = salesData?.data ? salesData?.data : [];

  const formatNumber = (value) => {
    return value.replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  };

  const handleChange = (e) => {
    let val = formatNumber(e.target.value);
    form.setFieldsValue({ cost: val });
  };

  const phoneNumberRegex = /^\+998\d{9}$/;

  useEffect(() => {
    setCanban_status(isVisible);
    form.setFieldsValue(
      expense
        ? {
            canban_status: expense?.canban_status,
            project_name: expense?.project_name,
            sales_agent: expense?.sales_agent?.id,
            description: expense?.description,
            phone_number: expense?.phone_number,
            date: dayjs(String(moment(Number(expense?.date)).format('DD/MM/YYYY')), dateFormat),
            comment: expense?.comment,
            cost: isFinite(expense?.cost)
              ? Number(expense.cost)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
              : '',

            currency: expense?.currency?.id,
            client_name: expense?.client_name,
          }
        : {
            canban_status: null,
            project_name: null,
            expense_types: null,
            method: null,
            date: null,
            comment: null,
            cost: null,
            current_rate: null,
            currency: null,
          },
    );
  }, [expense, form, isVisible]);

  const handleSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        const other = {
          canban_status,
          expense: expense,
        };
        onSave(values, isEditMode, other);
        form.resetFields();

        // onCancel();
      })
      .catch((error) => {
        console.error('Submission failed:', error?.message);
      });
  };

  const dateFormat = 'DD/MM/YYYY';

  const modalHeader = (
    <div>
      <h1>{isEditMode ? 'Edit project' : 'Add new project'}</h1>
      <p>{isEditMode ? 'Here you may edit.' : 'Here you may add new canban.'}</p>
    </div>
  );

  const handleCancel = () => {
    form.resetFields();
    onCancel();
  };

  return (
    <Modal
      open={isVisible}
      onCancel={handleCancel}
      destroyOnClose
      className="expenses-modal"
      width={990}
      footer={[
        <Button key="back" onClick={handleCancel}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" loading={isLoading} onClick={handleSubmit}>
          {isEditMode ? 'Update' : 'Save'}
        </Button>,
      ]}
    >
      <Form form={form} layout="vertical">
        <div className="form-wrapper">
          <div className="title">{modalHeader}</div>
          <div className="select_status">
            <h3>Select status</h3>
            <ul>
              {canbanStatus &&
                canbanStatus?.map((item) => (
                  <li
                    onClick={() => {
                      setCanban_status(item.id);
                    }}
                    style={
                      canban_status === item.id
                        ? {
                            border: `1px solid ${item.color}`,
                            color: '#ffffff',
                            backgroundColor: `${item.color}`,
                          }
                        : {
                            border: `1px solid ${item.color}`,
                            color: `${item.color}`,
                          }
                    }
                  >
                    {item.name}
                  </li>
                ))}
            </ul>
          </div>
          <div className="input-wrapper expenses-input-wrapper">
            <div className="single-input-item">
              <Form.Item
                name="project_name"
                label="Project name"
                rules={[
                  {
                    required: true,
                    message: 'Please fill the input',
                    // validateTrigger: 'onChange'
                  },
                ]}
                validateTrigger={['onBlur', 'onSubmit', 'onChange']}
              >
                <Input placeholder="Write name..." />
              </Form.Item>
            </div>

            <div className="single-input-item calendar">
              <Form.Item
                name="date"
                label="Date"
                rules={[
                  {
                    required: true,
                    message: 'Please input date!',
                  },
                ]}
                validateTrigger={['onBlur', 'onSubmit', 'onChange']}
              >
                <DatePicker
                  placeholder="DD/MM/YYYY"
                  format={dateFormat}
                  onChange={(dateString) => {
                    form.setFieldsValue({ date: dateString });
                  }}
                  defaultValue={expense?.date ? moment(expense.date) : null}
                />
              </Form.Item>
            </div>

            <div className="single-input-item salary">
              <Form.Item
                label="Cost"
                name="cost"
                className="salary-inner"
                rules={[
                  {
                    required: true,
                    message: 'Please fill',
                  },
                ]}
                validateTrigger={['onBlur', 'onSubmit', 'onChange']}
              >
                <Input
                  value={form.getFieldValue('cost')}
                  onChange={handleChange}
                  placeholder="Add salary..."
                  maxLength={10}
                />
              </Form.Item>
              <Form.Item
                className="currency"
                name="currency"
                rules={[
                  {
                    required: true,
                    message: 'required',
                  },
                ]}
                validateTrigger={['onBlur', 'onSubmit', 'onChange']}
              >
                <Select
                  placeholder="UZS"
                  style={{ width: 90 }}
                  loading={isCurrencyLoading}
                  // onChange={handleCurrencyChange}
                >
                  {currencies
                    ?.filter((c) => c.is_active)
                    .map((activeCity) => (
                      <Select.Option key={activeCity.id} value={activeCity.id}>
                        {activeCity.name}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
            </div>

            <div className="single-input-item">
              <Form.Item
                name="phone_number"
                label="Phone Number"
                rules={[
                  {
                    required: true,
                    message: 'Please fill the input',
                  },
                  {
                    pattern: { phoneNumberRegex },
                    message: '+998 ** *** ** **',
                  },
                ]}
                validateTrigger={['onBlur', 'onChange']}
              >
                <PhoneInput placeholder="Write phone number..." />
              </Form.Item>
            </div>

            <div className="single-input-item">
              <Form.Item
                name="client_name"
                label="Client name"
                rules={[
                  {
                    required: true,
                    message: 'Please fill the input',
                    // validateTrigger: 'onChange'
                  },
                ]}
                validateTrigger={['onBlur', 'onSubmit', 'onChange']}
              >
                <Input placeholder="Write name..." />
              </Form.Item>
            </div>

            <div className="single-input-item">
              <Form.Item
                name="sales_agent"
                label="Sale’s agent"
                rules={[
                  {
                    required: true,
                    message: 'Select agent...',
                  },
                ]}
                validateTrigger={['onBlur', 'onSubmit', 'onChange']}
              >
                <Select placeholder="Select agent...">
                  {sales &&
                    sales.map((member, index) => (
                      <Select.Option key={index} value={member?.id}>
                        {member.fullname} {member.role ? <strong>({member?.role})</strong> : null}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
            </div>
          </div>
          <div className="textarea_input">
            <div className="single-input-item">
              <Form.Item
                name="description"
                label="Description"
                rules={[
                  {
                    required: true,
                    message: 'Please fill the input',
                    // validateTrigger: 'onChange'
                  },
                ]}
                validateTrigger={['onBlur', 'onSubmit', 'onChange']}
              >
                <TextArea
                  placeholder="Controlled autosize"
                  autoSize={{
                    minRows: 3,
                    maxRows: 5,
                  }}
                />
              </Form.Item>
            </div>
          </div>
        </div>
      </Form>
    </Modal>
  );
};

export default KanbanModal;
