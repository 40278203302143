import React from 'react';

export const OrgStructure = () => {
  return (
    <div className="employees projects_log">
      {/* <div className="page-title">
        <h1 className="uppercase">Dynamic Soft structure</h1>
        <p>Here you may see information about employees</p>
      </div> */}
      <div className="upper-section">
        <img
          src={`https://erp1226.dynamicsoft.uz/org-structure.png`}
          alt="organization structure"
          width="100%"
          height="auto"
        />
      </div>
    </div>
  );
};

export default OrgStructure;
