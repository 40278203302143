import { Input, Space, DatePicker, Table, Popover, Spin } from 'antd';
import { useState, useEffect } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import useUniversalFetch from '../../../Hooks/useApi';
import { BASE_URL } from '../../../Consts/variables';
import moment from 'moment';
import dayjs from 'dayjs';
import 'dayjs/locale/en-gb'; // Use a locale where weeks start on Monday
import locale from 'antd/es/date-picker/locale/en_GB';

const userData = JSON.parse(localStorage.getItem('userData'));

// Search input
const { Search } = Input;

const getCurrentWeekRange = () => {
  return {
    week_start: dayjs().startOf('week').add(1, 'day').format('DD-MM-YYYY'), // Adjust to Monday
    week_end: dayjs().endOf('week').add(1, 'day').format('DD-MM-YYYY'), // Adjust to Sunday
  };
};

function InOut() {
  const [opacity, setOpacity] = useState('0');
  const [loader, setLoader] = useState('1');
  const [searchValue, setSearchValue] = useState('');
  // const [pagination, setPagination] = useState({
  //   current: 1,
  //   pageSize: 10,
  //   total: 0,
  // });

  const [weekRange, setWeekRange] = useState(getCurrentWeekRange());

  const token = typeof window !== 'undefined' && JSON.parse(localStorage.getItem('authToken'));
  const { useFetchQuery } = useUniversalFetch();

  const employeeIdParam = userData?.role === 'dev' ? `&employee_id=${userData.id}` : '';

  // Fetch attendance data based on the selected week
  const { data: attendenceData, isLoading: isAttendencesDataLoading } = useFetchQuery({
    queryKey: ['attendance', weekRange.week_start, weekRange.week_end], // Dynamic key for refetching
    token: token,
    url: `${BASE_URL}attendance`,
    id: `?from=${weekRange.week_start}&to=${weekRange.week_end}${employeeIdParam}`,
  });

  const onChange = (date) => {
    if (date) {
      setWeekRange({
        week_start: date.startOf('week').add(1, 'day').format('DD-MM-YYYY'),
        week_end: date.endOf('week').add(1, 'day').format('DD-MM-YYYY'),
      });
    }
  };

  // const handleTableChange = (pagination) => {
  //   setPagination((prev) => ({
  //     ...prev,
  //     current: pagination.current,
  //     pageSize: pagination.pageSize,
  //   }));
  // };

  useEffect(() => {
    if (!isAttendencesDataLoading) {
      setLoader('0');
      setOpacity('1');
    } else {
      setLoader('1');
      setOpacity('0');
    }
  }, [isAttendencesDataLoading, attendenceData]);

  // Sort employees by name and check-in/out status

  function sortEmployees(employees) {
    if (employees) {
      return employees?.sort((a, b) => {
        // Step 1: Sort by name (A-Z)
        const nameComparison = a.name.localeCompare(b.name);

        // Step 2: Check if all check-ins and check-outs are null
        const isAllNull = (attendance) =>
          attendance.every((day) => day.check_in === null && day.check_out === null);

        const aAllNull = isAllNull(a.attendance);
        const bAllNull = isAllNull(b.attendance);

        // If one has all nulls, it should go last
        if (aAllNull && !bAllNull) return 1;
        if (!aAllNull && bAllNull) return -1;

        // If both have the same null status, sort by name
        return nameComparison;
      });
    }
  }

  const data = {
    week_start: attendenceData?.data?.week_start,
    week_end: attendenceData?.data?.week_end,
    employees: sortEmployees(attendenceData?.data?.employees),
  };
  // const data = attendenceData?.data;

  const checkAttendanceStatus = (checkIn, checkOut) => {
    const checkInTime = checkIn ? moment(checkIn, 'HH:mm') : null;
    const checkOutTime = checkOut ? moment(checkOut, 'HH:mm') : null;
    const lateCheckIn = moment('09:15', 'HH:mm');
    const earlyCheckOut = moment('17:00', 'HH:mm');

    let checkInClass = 'gray';
    let checkOutClass = 'gray';

    if (checkInTime) {
      checkInClass = checkInTime.isAfter(lateCheckIn) ? 'red' : 'green';
    }
    if (checkOutTime) {
      checkOutClass = checkOutTime.isBefore(earlyCheckOut) ? 'red' : 'green';
    }

    return { checkInClass, checkOutClass };
  };

  const columns = [
    {
      title: <span className="name_employee">Employee Name</span>,
      dataIndex: 'name',
      render: (name) => (
        <span className="employee_name">
          <Popover placement="bottom" content={name}>
            <p className="name_wrapper">{name}</p>
          </Popover>
        </span>
      ),
    },
    ...['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'].map(
      (day) => ({
        title: (
          <div className="column_name">
            <span>{day}</span>
            <p>{data?.employees?.[0]?.attendance?.find((d) => d.day === day)?.date}</p>
          </div>
        ),
        dataIndex: day,
        width: 130,
        render: (_, record) => {
          const attendance = record?.attendance.find((d) => d.day === day);
          if (!attendance) return null;

          const { checkInClass, checkOutClass } = checkAttendanceStatus(
            attendance.check_in,
            attendance.check_out,
          );

          return (
            <span className="status">
              <p className={`time in ${checkInClass}`}>{attendance.check_in || '--:--'}</p>
              <p className={`time out ${checkOutClass}`}>{attendance.check_out || '--:--'}</p>
            </span>
          );
        },
      }),
    ),
  ];

  // Correct `tableData` mapping
  const tableData = data?.employees?.map((employee) => ({
    key: employee?.name,
    name: employee?.name,
    attendance: employee.attendance,
  }));

  // 🔍 Filter employees based on searchValue
  const filteredData = tableData?.filter((employee) =>
    employee.name.toLowerCase().includes(searchValue.toLowerCase()),
  );

  return (
    <>
      <div className="ina_out" style={{ opacity: opacity }}>
        <div className="page-title">
          <h1>In & Out</h1>
          <p>Here you may see information about check in and out</p>
        </div>
        <div className="upper-section">
          <div className="left">
            {(userData?.role === 'hr' || userData?.role === 'ceo' || userData?.role === 'pm') && (
              <div className="download-btn">
                <div className="search-btn">
                  <Space direction="vertical">
                    <Search
                      placeholder="Search employee..."
                      onChange={(e) => setSearchValue(e.target.value)}
                      style={{ width: 200 }}
                    />
                  </Space>
                </div>
              </div>
            )}
            <div className="select">
              <DatePicker
                onChange={onChange}
                picker="week"
                defaultValue={dayjs()}
                locale={locale}
              />
            </div>
          </div>
        </div>
        <div className="table">
          <Table
            columns={columns}
            dataSource={filteredData} // Use filtered data
            loading={isAttendencesDataLoading}
            // onChange={handleTableChange}
            pagination={false}
          />
        </div>
      </div>
      <div className="loader" style={{ opacity: loader }}>
        <Spin indicator={<LoadingOutlined style={{ fontSize: 44 }} spin />} />
      </div>
    </>
  );
}

export default InOut;
