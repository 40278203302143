import React, { useState, useEffect } from 'react';
import { Drawer, Layout, Menu } from 'antd';
import Navbar from '../Components/Navbar';
import Pages from './Pages';
import { useNavigate, useLocation } from 'react-router-dom';
import Icon from '../Components/Icon';
import Logo from '../Assets/images/logo_name.svg';
import LogoMin from '../Assets/images/single_logo.svg';
// import Login from './Auth/Login';

const { Sider } = Layout;

function getItem(label, key, icon, children) {
  return {
    key,
    icon,
    // roles,
    children,
    label,
  };
}

const sidebarItems = [
  {
    name: 'Projects',
    path: '/projects',
    icon: 'projects',
    accessRoles: ['ceo', 'hr', 'pm', 'sales'],
  },
  {
    name: 'Employees',
    path: '/employees',
    icon: 'emploees',
    accessRoles: ['hr', 'ceo'],
  },
  {
    name: 'Log',
    path: '/log',
    icon: 'log',
    children: [
      {
        name: 'Projects',
        path: '/log/',
        accessRoles: ['ceo', 'pm'],
      },
    ],
    accessRoles: ['ceo', 'pm'],
  },
  {
    name: 'Settings',
    path: '/settings',
    icon: 'setting',
    children: [
      {
        name: 'Job title',
        path: '/settings/job-title',
        accessRoles: ['ceo', 'hr', 'pm', 'sales'],
      },
      {
        name: 'Currency',
        path: '/settings/currency',
        accessRoles: ['ceo', 'hr', 'pm', 'sales'],
      },
      {
        name: 'Country',
        path: '/settings/country',
        accessRoles: ['ceo', 'hr', 'pm', 'sales'],
      },
      {
        name: 'Project type',
        path: '/settings/project-type',
        accessRoles: ['ceo', 'hr', 'pm', 'sales'],
      },
      {
        name: 'Integration',
        path: '/settings/integration',
        accessRoles: ['ceo', 'hr', 'pm', 'sales'],
      },
      {
        name: 'Canban status',
        path: '/settings/canbantype',
        accessRoles: ['ceo', 'hr', 'pm', 'sales'],
      },
      {
        name: 'Experiance',
        path: '/settings/experiance',
        accessRoles: ['ceo', 'hr', 'pm', 'sales'],
      },
      {
        name: 'Expense Type',
        path: '/settings/expense-type',
        accessRoles: ['ceo', 'hr', 'pm', 'sales'],
      },
      {
        name: 'Income Type',
        path: '/settings/income-type',
        accessRoles: ['ceo', 'hr', 'pm', 'sales'],
      },
      {
        name: 'Company wage',
        path: '/settings/company-wage',
        accessRoles: ['ceo'],
      },
    ],
    accessRoles: ['ceo', 'hr', 'sales'],
  },
  {
    name: 'Sales',
    path: '/sales',
    icon: 'sales',
    children: [
      {
        name: 'Calculator',
        path: '/sales/calculator',
        accessRoles: ['ceo', 'sales'],
      },
      {
        name: 'Canban',
        path: '/sales/canban',
        accessRoles: ['ceo', 'sales'],
      },
    ],
    accessRoles: ['ceo', 'sales'],
  },
  {
    name: 'Finance',
    path: '/finance',
    icon: 'finance',
    children: [
      {
        name: 'Dashboard',
        path: '/finance/dashboard',
        accessRoles: ['ceo'],
      },
      {
        name: 'Expenses',
        path: '/finance/expenses',
        accessRoles: ['ceo'],
      },
      {
        name: 'Income',
        path: '/finance/income',
        accessRoles: ['ceo'],
      },
      {
        name: 'Support',
        path: '/finance/support',
        accessRoles: ['ceo'],
      },
    ],
    accessRoles: ['ceo'],
  },
  {
    name: 'Task playground',
    path: '/task-playgrounds',
    icon: 'playground',
    accessRoles: ['ceo', 'pm', 'sales', 'dev', 'hr'],
  },
  // {
  //   name: 'Org structure',
  //   path: '/org-structure',
  //   icon: 'org-structure',
  //   accessRoles: ['ceo', 'pm', 'sales', 'dev', 'hr'],
  // },
];

// getItem('Projects', '/projects', <Icon icon="projects" />,),
// getItem('Employees', '/employees', <Icon icon="emploees" />,),
// getItem('Log', '/log', <Icon icon="log" />, [
//   getItem('Projects', '/log/'),
//   getItem('Bill', '/log/2'),
//   getItem('Alex', '/log/3'),
// ]),
// getItem('Sales', '/sales', <Icon icon="sales" />,),
// getItem('Settings', '/settings', <Icon icon="setting" />, [
//   getItem('Job title', '/settings/job-title'), //
//   getItem('Project type', '/settings/project-type'),
//   getItem('Integration', '/settings/integration'),
//   getItem('Currency', '/settings/currency'),
//   getItem('Country', '/settings/country'),
// ]),
// getItem('Sales', '/s', <Icon icon="sales" />, ),

const Build = () => {
  const userData = JSON.parse(localStorage.getItem('userData'));

  let role = userData?.role;

  let itemsTop = [];
  for (let i = 0; i < sidebarItems.length; i++) {
    const element = sidebarItems[i];
    if (element.accessRoles.includes(role)) {
      itemsTop.push(
        getItem(
          element.name,
          element.path,
          <Icon icon={element.icon} />,
          // eslint-disable-next-line array-callback-return
          element?.children?.map((el) => {
            if (el?.accessRoles?.includes(role)) {
              return getItem(el.name, el.path);
            }
          }),
        ),
      );
    }
  }

  const [collapsed, setCollapsed] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;

  const handleTopMenuClick = ({ key }) => {
    navigate(key);
  };

  const handleLogOut = () => {
    window.localStorage.removeItem('userData');
    window.localStorage.removeItem('authToken');
    localStorage.removeItem('token_expiration');
    navigate('/login');
  };

  useEffect(() => {
    if (window.innerWidth >= 700) {
      setCollapsed(false);
    }
  }, []);

  const itemsBottom = [
    // getItem('Language', "2", <Icon icon="eng" />),
    getItem('Log out', '3', <Icon icon="logout" />, null, { color: 'red' }),
  ];

  const handleBottomMenuClick = (item) => {
    switch (item.key) {
      case '1':
        console.log('Setting clicked');
        break;
      case '2':
        console.log('Language clicked');
        break;
      case '3':
        console.log('Log out clicked');
        handleLogOut();
        break;
      default:
        break;
    }
  };

  const checkTokenExpiration = () => {
    const expiration = localStorage.getItem('token_expiration');
    if (!expiration) return false;

    const now = new Date();
    const expirationDate = new Date(expiration);
    return now >= expirationDate;
  };

  useEffect(() => {
    if (checkTokenExpiration()) {
      handleLogOut();
    } else {
      const now = new Date();
      const expiration = new Date(localStorage.getItem('token_expiration'));
      const timeUntilExpiration = expiration - now;

      const timer = setTimeout(handleLogOut, timeUntilExpiration);

      return () => clearTimeout(timer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  useEffect(() => {
    if (window.innerWidth <= 700) {
      setCollapsed(false);
    }
  }, [pathname]);

  return (
    <div className="layout">
      {pathname !== '/login' ? (
        <>
          <Layout style={{ minHeight: 'calc(100vh - 80px)' }}>
            <Sider
              collapsible
              collapsed={collapsed}
              onCollapse={(value) => setCollapsed(value)}
              className="menu_desktop"
            >
              <div className="demo_logo">
                <img
                  src={LogoMin}
                  alt="Logo"
                  className={collapsed ? 'nav_logo_mini' : 'logo_min'}
                />
                <img src={Logo} alt="Logo" className={collapsed ? 'nav_logo_min' : 'nav_logo'} />
              </div>
              <div className="sider_wrapper">
                <Menu
                  defaultSelectedKeys={pathname}
                  mode="inline"
                  items={itemsTop}
                  onClick={handleTopMenuClick}
                />
                <Menu
                  className="secondary-menu"
                  mode="inline"
                  items={itemsBottom}
                  onClick={handleBottomMenuClick}
                />
              </div>
            </Sider>

            <Drawer
              placement="left"
              collapsible
              open={collapsed}
              onClose={(value) => setCollapsed(false)}
              className="menu_mobile"
              width={430}
            >
              <div className="demo-logo-vertical" />
              <Menu
                defaultSelectedKeys={pathname}
                mode="inline"
                items={itemsTop}
                onClick={handleTopMenuClick}
              />
              <Menu
                className="secondary-menu"
                mode="inline"
                items={itemsBottom}
                onClick={handleBottomMenuClick}
              />
            </Drawer>

            <Layout>
              <Navbar setCollapsed={setCollapsed} collapsed={collapsed} />
              <div className="content">
                <Pages />
              </div>
            </Layout>
          </Layout>
        </>
      ) : (
        <Pages />
      )}
    </div>
  );
};

export default Build;
