import React, { useEffect, useState } from "react";
import { CloseOutlined, LoadingOutlined } from "@ant-design/icons";
import { Button, Form, Input, Select, Space, Spin } from "antd";
import Icon from "../../../../Components/Icon";
import useUniversalFetch from "../../../../Hooks/useApi";
import { BASE_URL } from "../../../../Consts/variables";
import CustomModal from "./resultModal";

function ProjectInner() {
  // const [position, setPosition] = useState("end");
  // eslint-disable-next-line no-unused-vars
  const [days, setDays] = useState();
  const [team, setTeam] = useState([]);
  const [form] = Form.useForm();
  const [modalVisible, setModalVisible] = useState(false);
  const [confirmModalVisible, setConfirmModalVisible] = useState(false);
  const [result, setResult] = useState();
  const [modalLoader, setModalLoader] = useState("modal-loader");
  const [modalInnerVisibility, setModalInnerVisibility] = useState("0");
  const [opacity, setOpacity] = useState("0");
  const [loader, setLoader] = useState("1");
  const [isButtonVisible, setIsButtonVisible] = useState(false);

  const token =
    typeof window !== "undefined" &&
    JSON.parse(localStorage.getItem("authToken"));

  const { useFetchQuery, useFetchMutation } = useUniversalFetch();

  const {
    data: integrationData,
    isLoading: isIntegrationLoading,
    // error: integrationError,
    // isError: isIntegrationError,
    // isSuccess: isSuccesIntegration,
  } = useFetchQuery({
    queryKey: "Integration",
    token: token,
    url: `${BASE_URL}setting/integration`,
    // id: `${isActive ? `?is_active=${isActive}` : ""}`,
  });

  const {
    data: calculateData,
    // isSuccess: isSuccessCalculated,
    mutate: Calculate,
    isLoading: isCalculateLoading,
    // error: calculateError,
    // isError: isCalculateError,
  } = useFetchMutation({
    url: `${BASE_URL}sales/calculate`,
    method: "POST",
    token: token,
  });

  const {
    data: typeData,
    isLoading: isTypeLoading,
    // error: typeError,
    // isError: isTypeError,
    isSuccess: isSuccesType,
  } = useFetchQuery({
    queryKey: "Type",
    token: token,
    url: `${BASE_URL}setting/project-type`,
  });

  useEffect(() => {
    if (!isTypeLoading && typeData && isSuccesType) {
      setLoader("0");
      setOpacity("1");
    } else {
      setLoader("1");
      setOpacity("0");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTypeLoading, typeData]);

  const projectTypes = typeData?.status === 200 ? typeData.data : [];

  const calculatedData =
    calculateData?.status === 200 ? calculateData.data : [];

  const integrations =
    integrationData?.status === 200 ? integrationData.data : [];

  const {
    data: employeeData,
    isLoading: isEmployeeDataLoading,
    // error: employeeDataError,
    // isError: isEmployeeDataError,
    // isSuccess: isSuccesEmployeeData,
  } = useFetchQuery({
    queryKey: "employee",
    token: token,
    url: `${BASE_URL}employee/find-by-role`,
    id: `?role=${"dev"}`,
  });

  const {
    data: pmData,
    isLoading: isPmDataLoading,
    // error: pmDataError,
    // isError: isPmDataError,
    // isSuccess: isSuccespmData,
  } = useFetchQuery({
    queryKey: "pm",
    token: token,
    url: `${BASE_URL}employee/find-by-role`,
    id: `?role=${"pm"}`,
  });

  useEffect(() => {
    setResult(calculatedData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calculateData]);

  const employees = employeeData?.data ? employeeData?.data : [];
  const pms = pmData?.data ? pmData?.data : [];
  //   console.log(employees);

  const onKeyDown = (e) => {
    const { key } = e;
    // Allow: backspace, delete, tab, escape, enter, and navigation keys (arrow keys)
    if (key === "Backspace" || key === "Delete" || (key >= "0" && key <= "9")) {
      return;
    }
    // Prevent default behavior for non-numeric keys
    e.preventDefault();
  };

  const formatNumber = (value) => {
    return value.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  };

  const handleChangeNumber = (e) => {
    let val = formatNumber(e.target.value);
    setDays(val);
  };

  //   const checkLoaader = () => {
  //     if (!isCalculateLoading && calculateData && isSuccessCalculated) {
  //       setModalLoader("modal-loader hide-modal-loader");
  //     //   setModalInnerVisibility("1");
  //     } else {
  //       setModalLoader("modal-loader");
  //     //   setModalInnerVisibility("0");
  //     }
  //   };

  useEffect(() => {
    setTimeout(() => {
      if (!isCalculateLoading) {
        setModalLoader("modal-loader hide-modal-loader");
        setModalInnerVisibility("1");
      }
    }, 5000);
  }, [isCalculateLoading]);

  const options = [];
  for (let i = 10; i < 36; i++) {
    options.push({
      label: i.toString(36) + i,
      value: i.toString(36) + i,
    });
  }
  const handleChange = (value) => {
    console.log(`selected ${value}`);
  };
  const handleCalculate = () => {
    setModalLoader("modal-loader");
    setModalInnerVisibility("0");
    Calculate({
      client_name: form.getFieldValue("fullname"),
      deadline: Number(form.getFieldValue("deadline")),
      project_type: form.getFieldValue("projecttype"),
      integration: form.getFieldValue("integration"),

      team: team.map((item) => ({ id: item.dev.id, days: Number(item.day) })),
      pm: form.getFieldValue("pmanager"),
    });
    handleOpenModal();
  };
  const recalculate = () => {
    handleSubmit();
    // handleCalculate();
    // checkLoaader();
  };

  //   useEffect(() => {
  //     setTimeout(() => {
  //       checkLoaader();
  //     }, 6000);
  //   }, [isCalculateLoading, calculatedData, recalculate]);

  function handleRemoveTeamMember(id) {
    setTeam(team.filter((user) => user.id !== id));
  }

  const handleOpenModal = () => {
    setModalVisible(true);
  };

  const handleCloseModal = () => {
    // handleCalculate();
    setModalVisible(false);
  };

  const handleOpenConfirmModal = () => {
    setConfirmModalVisible(true);
  };

  const handleCloseModalConfirm = () => {
    setConfirmModalVisible(false);
  };

  const handleValuesChange = (changedValues, allValues) => {
    const hasValues = Object.values(allValues).some(
      (value) => value !== undefined && value !== ""
    );
    setIsButtonVisible(hasValues);
  };

  const handleOkClear = () => {
    form.resetFields();
    setIsButtonVisible(false);
    setTeam([]);
    setConfirmModalVisible(false);
  };

  const handleOk = () => {
    setModalVisible(false);
  };

  const handleSubmit = () => {
    form
      .validateFields()
      .then(() => {
        handleCalculate();
        // onCancel();
      })
      .catch((error) => {
        console.error("Submission failed:", error?.message);
      });
  };

  return (
    <>
      <div className="sales" style={{ opacity: opacity }}>
        <div className="page-title">
          <div className="title-wrapper">
            <h1 className="uppercase">Calculator</h1>
            <p>Here you may see information about sales</p>
          </div>
          <div className="calculation-btns">
            {isButtonVisible && (
              <Button
                type="primary"
                icon={<Icon icon="reset" />}
                iconPosition="end"
                className="calculate-btn clear-btn"
                onClick={handleOpenConfirmModal}
              >
                Clear all
              </Button>
            )}
            <Button
              type="primary"
              icon={<Icon icon="calculator" />}
              iconPosition="end"
              className="calculate-btn"
              onClick={handleSubmit}
            >
              Calculate
            </Button>
          </div>
          <CustomModal
            visible={modalVisible}
            onCancel={handleCloseModal}
            onOk={handleOk}
            recalculate={recalculate}
            footerBtn={"Recalculate"}
            heading={"Result"}
            titleClass={"title"}
          >
            <div className="result" style={{ opacity: modalInnerVisibility }}>
              <div className="result-wrapper">
                <div className="left item">
                  <span>Price</span>
                  <h3>
                    {result?.total_sum
                      ? Number(result.total_sum)
                          .toFixed(2)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, " ")
                      : ""}{" "}
                    {""}
                    UZS
                  </h3>
                </div>
                <div className="right item">
                  <span>Deadline</span>
                  <h3>{result?.deadline} days</h3>
                </div>
              </div>
              <div className="totel">
                <p>
                  It takes {result?.deadline} days to complete this task and
                  price of the project is{" "}
                  {result?.total_sum
                    ? Number(result.total_sum)
                        .toFixed(2)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, " ")
                    : ""}{" "}
                  {""}
                  UZS
                </p>
              </div>
            </div>
            <div className={modalLoader}>
              <div className="loader-wrapper">
                <div class="lds-roller">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
                <span>Calculating...</span>
              </div>
            </div>
          </CustomModal>
          <CustomModal
            visible={confirmModalVisible}
            onCancel={handleCloseModalConfirm}
            onOk={handleOkClear}
            recalculate={handleCloseModalConfirm}
            footerBtn={"Cencel"}
            heading={"Clear all"}
            titleClass={"title min-title"}
          >
            <div className="confirm-content">
              <p>Are you sure you want to clear all?</p>
            </div>
          </CustomModal>
        </div>
        <Form onValuesChange={handleValuesChange} form={form} layout="vertical">
          <div className="form-wrapper">
            <div className="client-info">
              <div className="single-input-item">
                <Form.Item
                  name="fullname"
                  label="Client name"
                  rules={[
                    {
                      required: true,
                      message: "Please fill the input",
                      // validateTrigger: 'onChange'
                    },
                  ]}
                  validateTrigger={["onBlur", "onSubmit", "onChange"]}
                >
                  <Input placeholder="Write full name..." />
                </Form.Item>
              </div>
              <div className="single-input-item">
                <Form.Item
                  name="pmanager"
                  label="PM"
                  rules={[
                    {
                      required: true,
                      message: "Please select",
                    },
                  ]}
                  validateTrigger={["onBlur", "onSubmit", "onChange"]}
                >
                  <Select
                    placeholder="Select title..."
                    loading={isPmDataLoading}
                  >
                    {pms &&
                      pms.map((member, index) => (
                        <Select.Option key={index} value={member.id}>
                          {member.fullname}{" "}
                          {member.role ? (
                            <strong>({member.role})</strong>
                          ) : null}
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>
              </div>
            </div>
            <div className="project-info">
              <div className="single-input-item multiple">
                <Form.Item
                  name="projecttype"
                  label="Project type"
                  rules={[
                    {
                      required: true,
                      message: "Please select",
                      // validateTrigger: 'onChange'
                    },
                  ]}
                  validateTrigger={["onBlur", "onSubmit", "onChange"]}
                >
                  <Select
                    mode="multiple"
                    allowClear
                    style={{
                      width: "100%",
                    }}
                    loading={isTypeLoading}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    placeholder="Please select"
                    //   defaultValue={["a10", "c12"]}
                    onChange={handleChange}
                  >
                    {projectTypes
                      ?.filter((c) => c.is_active)
                      .map((activeTitle) => (
                        <Select.Option
                          key={activeTitle.id}
                          value={activeTitle.id}
                        >
                          {activeTitle.name}
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>
                <br />
                <br />
                <Form.Item
                  name="deadline"
                  label="Deadline"
                  rules={[
                    {
                      required: true,
                      message: "Please enter a number",
                    },
                    {
                      pattern: /^[1-9]\d{0,2}$/,
                      message: "Number must be between 1 and 3 digits",
                    },
                  ]}
                >
                  <Input
                    maxLength={3}
                    placeholder="Days"
                    onKeyDown={onKeyDown}
                  />
                </Form.Item>
              </div>
              <div className="single-input-item multiple">
                <Form.Item name="integration" label="Integration">
                  <Select
                    mode="multiple"
                    allowClear
                    style={{
                      width: "100%",
                    }}
                    loading={isIntegrationLoading}
                    placeholder="Please select"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    //   defaultValue={["Mobile app with web application", "Mobile"]}
                    //   onChange={handleChange}
                  >
                    {integrations
                      ?.filter((c) => c.is_active)
                      .map((activeTitle) => (
                        <Select.Option
                          key={activeTitle.id}
                          value={activeTitle.id}
                        >
                          {activeTitle.name}
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>
              </div>
              <div className="single-input-item twin-input">
                <Form.Item label="Team">
                  <Space.Compact>
                    <Form.Item
                      name="team"
                      //   rules={[
                      //     {
                      //       required: team.length ? false : true,
                      //       message: "Please select",
                      //     },
                      //   ]}
                      //   validateTrigger={["onBlur", "onSubmit", "onChange"]}
                    >
                      <Select
                        placeholder="Select team"
                        showSearch
                        loading={isEmployeeDataLoading}
                        filterOption={(input, option) =>
                          option.props.children[0]
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {employees &&
                          employees.map((member, index) => (
                            <Select.Option
                              disabled={team.find(
                                (el) => el.dev.id === member.id
                              )}
                              key={index}
                              value={member.id}
                            >
                              {member.fullname}{" "}
                              {member.role ? (
                                <strong>({member.role})</strong>
                              ) : null}
                            </Select.Option>
                          ))}
                      </Select>
                    </Form.Item>

                    <Form.Item
                      name="teamNumber"
                      className="team-menber-deadline"
                      noStyle
                      label="Days"
                      rules={[
                        // {
                        //   required: team.length ? false : true,
                        //   message: "",
                        // },
                        {
                          pattern: /^[1-9]\d{0,2}$/,
                          message: "",
                        },
                      ]}
                    >
                      <Input
                        //   value={member.days}
                        onChange={handleChangeNumber}
                        maxLength={3}
                        placeholder="Days"
                        onKeyDown={onKeyDown}
                      />
                    </Form.Item>
                    <Button
                      disabled={
                        form.getFieldValue("teamNumber") > 0 &&
                        form.getFieldValue("team")
                          ? false
                          : true
                      }
                      onClick={() => {
                        setTeam([
                          ...team,
                          {
                            id: new Date().getTime(),
                            day: Number(form.getFieldValue("teamNumber")),
                            dev: employees.find(
                              (employee) =>
                                employee.id === form.getFieldValue("team")
                            ),
                          },
                        ]);
                        form.resetFields(["team", "teamNumber"]);
                      }}
                      type="primary"
                    >
                      Add
                    </Button>
                  </Space.Compact>
                </Form.Item>

                <div className="team-list">
                  <ul>
                    {team &&
                      team
                        .sort((a, b) => a.id - b.id)
                        .map((member, index) => (
                          <li key={index}>
                            <div className="item">
                              <div className="fullname">
                                <div className="name">
                                  <h4>{member.dev.fullname}</h4>
                                </div>
                                <span className="role">
                                  {member.dev.job_title.name}
                                </span>
                              </div>
                              <div className="duration">
                                <Input
                                  value={member.day}
                                  onChange={(e) => {
                                    const oldUsers = team.filter(
                                      (user) => member.id !== user.id
                                    );
                                    const foundUser = team.find(
                                      (user) => member.id === user.id
                                    );

                                    setTeam([
                                      ...oldUsers,
                                      {
                                        id: foundUser.id,
                                        day: e.target.value,
                                        dev: foundUser.dev,
                                      },
                                    ]);
                                  }}
                                  maxLength={3}
                                  defaultValue={member.day}
                                />
                                <span className="days">Days</span>
                              </div>
                              <CloseOutlined
                                onClick={() =>
                                  handleRemoveTeamMember(member.id)
                                }
                              />
                            </div>
                          </li>
                        ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </Form>
      </div>
      <div className="loader" style={{ opacity: loader }}>
        <Spin
          indicator={
            <LoadingOutlined
              style={{
                fontSize: 44,
              }}
              spin
            />
          }
        />
      </div>
    </>
  );
}

export default ProjectInner;
