import React, { useState, useEffect } from 'react';
import {
  Input,
  Space,
  Modal,
  Select,
  Button,
  Table,
  Popover,
  message,
  notification,
  Spin,
} from 'antd';
import { PlusOutlined, ExclamationCircleFilled, LoadingOutlined } from '@ant-design/icons';
import Icon from '../../../Components/Icon';
import EmployeeModal from './EmployeeModal';
import FileAdd from './EmployeeModal/fileModal';
import { BASE_URL } from '../../../Consts/variables';
import useUniversalFetch from '../../../Hooks/useApi';
import AvatarConfirmed from '../../../Assets/images/confirmed_avatar.svg';
import AvatarNotConfirmed from '../../../Assets/images/not_confirmed_avatar.svg';
import AvatarArchived from '../../../Assets/images/archived_avatar.svg';
import moment from 'moment';
import { RoleController } from '../../../Hooks/role-controller';
import { useLocation } from 'react-router-dom';
// import { current } from '@reduxjs/toolkit';

const { confirm } = Modal;

const Employees = () => {
  const { pathname } = useLocation();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentEmployee, setCurrentEmployee] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [isFileslVisible, setIsFilesVisible] = useState(false);
  const [employeeFiles, setEmployeeFiles] = useState(null);
  const [companyValue, setCompanyValue] = useState('all');
  const [statusValue, setStatusValue] = useState('all');
  const [opacity, setOpacity] = useState('0');
  const [loader, setLoader] = useState('1');
  const [title, setTitle] = useState('all');
  const [visibility, setVisibility] = useState('invisible');
  const [employeeId, setEmployeeId] = useState(null);
  const [showSalary, setShowSalary] = useState('salary hide-salary');
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });

  const token = typeof window !== 'undefined' && JSON.parse(localStorage.getItem('authToken'));
  const { useFetchQuery, useFetchMutation, usePatchMutation, useDeleteMutation } =
    useUniversalFetch();
  const {
    data: employeeData,
    isLoading: isEmployeeDataLoading,
    // error: employeeDataError,
    // isError: isEmployeeDataError,
    isSuccess: isSuccesEmployeeData,
  } = useFetchQuery({
    queryKey: 'employee',
    token: token,
    url: `${BASE_URL}employee`,
    id: `?page=${pagination.current}&page_size=${pagination.pageSize}&search=${searchValue}${
      title !== 'all' ? `&job_title=${title}` : ''
    }${companyValue !== 'all' ? `&company=${companyValue}` : ''}${
      statusValue !== 'all' ? `&status=${statusValue}` : ''
    }`,
  });

  const {
    data: jobTitleData,
    // isLoading: isJobTitleLoading,
    // error: jobTitleError,
    // isError: isJobTitleError,
  } = useFetchQuery({
    queryKey: 'jobTitle',
    token,
    url: `${BASE_URL}setting/job-title`,
  });

  const jobTitles = jobTitleData?.status === 200 ? jobTitleData.data : null;

  const employees = employeeData ? employeeData : [];

  const {
    data: companyData,
    // isLoading: isCompanyLoading,
    // error: companyError,
    // isError: isCompanyError,
  } = useFetchQuery({
    queryKey: 'company',
    token,
    url: `${BASE_URL}company`,
  });

  const company = companyData?.status === 200 ? companyData.data : null;

  useEffect(() => {
    if (!isEmployeeDataLoading && employeeData && isSuccesEmployeeData) {
      setLoader('0');
      setOpacity('1');
    } else {
      setLoader('1');
      setOpacity('0');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEmployeeDataLoading, employeeData]);

  const {
    data: employeeCreateData,
    isSuccess: isSuccessCreated,
    mutate: employeeCreate,
    isLoading: isEmployeeCreateLoading,
    error: employeeCreateError,
    isError: isEmployeeCreateError,
  } = useFetchMutation({
    url: `${BASE_URL}employee`,
    method: 'POST',
    token: token,
  });

  const {
    data: employeeUpdateData,
    isSuccess: isSuccessUpdated,
    mutate: employeeUpdate,
    isLoading: isEmployeeUpdateLoading,
    error: employeeUpdateError,
    isError: isEmployeeUpdateError,
  } = usePatchMutation({
    url: `${BASE_URL}employee`,
    method: 'PATCH',
    token: token,
  });

  const {
    data: employeeDeleteData,
    isSuccess: isSuccessDelete,
    mutate: employeeDelete,
    // isLoading: isEmployeeDeleteLoading,
    error: employeeDeleteError,
    isError: isEmployeeDeleteError,
  } = useDeleteMutation({
    url: `${BASE_URL}employee`,
    method: 'DELETE',
    token: token,
  });

  const showDeleteConfirm = (employeeId) => {
    confirm({
      title: 'Are you sure delete this list ?',
      icon: <ExclamationCircleFilled />,
      content: 'Deleted employee cannot be recovered',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      className: 'delete-pop-up',
      onOk() {
        employeeDelete({
          id: employeeId.id,
        });
        // setDeleteId(todoId)
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };

  useEffect(() => {
    if (isSuccessUpdated) {
      notification.success({
        // message: 'Project created',
        message: employeeUpdateData?.message,
      });
      setIsModalVisible(false);
    } else if (isEmployeeUpdateError) {
      notification.error({
        message: 'Data not updated',
        description: employeeUpdateError?.message,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEmployeeUpdateError, employeeUpdateData, employeeUpdateError]);

  useEffect(() => {
    if (isSuccessDelete) {
      notification.success({
        message: employeeDeleteData?.message,
      });
    } else if (isEmployeeDeleteError) {
      notification.error({
        message: 'Data not deleted',
        description: employeeDeleteError?.message,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEmployeeDeleteError, employeeDeleteData, employeeDeleteError]);

  useEffect(() => {
    if (isSuccessCreated) {
      notification.success({
        // message: 'Project created',
        message: employeeCreateData?.message,
      });
      setIsModalVisible(false);
    } else if (isEmployeeCreateError) {
      notification.error({
        message: 'Data not created',
        description: employeeCreateError?.message,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEmployeeCreateError, employeeCreateData, employeeCreateError]);

  const handleCancelFile = () => {
    setIsFilesVisible(false);
    // notification.info({
    //   message: 'Operation Cancelled',
    //   description: 'No changes were saved.'
    // });
  };
  const handleSaveFile = (employeeFile) => {
    setEmployeeFiles(employeeFile);
    // notification.success({
    //   message: 'Project Added',
    //   description: 'A new project has been added successfully.'
    // });
    setIsFilesVisible(false);
  };

  const showFilesModal = (record) => {
    setEmployeeFiles(record);
    // console.log(files)
    setIsFilesVisible(true);
    setEmployeeId(record.id);
  };

  const { Search } = Input;

  const handleCompanyChange = (value) => {
    setCompanyValue(value);
  };
  const handleStatusChange = (value) => {
    setStatusValue(value);
  };
  const handleTitleChange = (value) => {
    setTitle(value);
  };

  const handleCopyText = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        message.info('Copied');
      })
      .catch((error) => {
        console.error('Unable to copy text to clipboard:');
      });
  };

  const showEditModal = (employee) => {
    setCurrentEmployee(employee);
    setIsEditMode(true);
    setIsModalVisible(true);
  };

  const showCreateModal = () => {
    setCurrentEmployee(null);
    setIsEditMode(false);
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setCurrentEmployee(null);
  };

  const handleShowSalary = () => {
    if (showSalary === 'salary hide-salary') {
      setShowSalary('salary');
    } else {
      setShowSalary('salary hide-salary');
    }
    if (visibility === 'invisible') {
      setVisibility('visible');
    } else {
      setVisibility('invisible');
    }
  };

  const handleSubmit = (employeeData, isEdit) => {
    if (!isEdit) {
      employeeCreate({
        fullname: employeeData.fullname,
        username: employeeData.username,
        is_lead: employeeData.is_lead,
        phone_number: employeeData.phone_number,
        address: employeeData.address,
        role: employeeData.role,
        status: employeeData.status,
        date_join: employeeData.date_join
          ? new Date(employeeData?.date_join).getTime()
          : currentEmployee.date_join,
        birth_date: employeeData.birth_date
          ? new Date(employeeData?.birth_date).getTime()
          : currentEmployee.birth_date,
        email: employeeData.email,
        telegram: employeeData.telegram,
        salary: Number(employeeData.salary.replace(/[^\d]/g, '')),
        currency: {
          id: employeeData.currency,
        },
        job_title: {
          id: employeeData.job_title,
        },
        region: {
          id: employeeData.region,
        },
        company: {
          id: employeeData.company,
        },
        work_experience: {
          id: employeeData.work_experience,
        },
        password: employeeData.password,
      });
    } else {
      employeeUpdate({
        id: currentEmployee.id,
        data: {
          is_lead: employeeData.is_lead,
          fullname: employeeData.fullname,
          username: employeeData.username,
          phone_number: employeeData.phone_number,
          address: employeeData.address,
          role: employeeData.role,
          status: employeeData.status,
          date_join: employeeData.date_join
            ? new Date(employeeData?.date_join).getTime()
            : currentEmployee.date_join,
          birth_date: employeeData.birth_date
            ? new Date(employeeData?.birth_date).getTime()
            : currentEmployee.birth_date,
          email: employeeData.email,
          telegram: employeeData.telegram,
          salary: Number(employeeData.salary.replace(/[^\d]/g, '')),
          currency: {
            id: employeeData.currency ? employeeData.currency : currentEmployee.currency.id,
          },
          job_title: {
            id: employeeData.job_title ? employeeData.job_title : currentEmployee.job_title.id,
          },
          region: {
            id: employeeData.region ? employeeData.region : currentEmployee.region.id,
          },
          company: {
            id: employeeData.company ? employeeData.company : currentEmployee.company[0].id,
          },
          work_experience: {
            id: employeeData.work_experience
              ? employeeData.work_experience
              : currentEmployee.work_experience.id,
          },
          password: employeeData.password,
        },
      });
    }
  };

  const handleTableChange = (pagination) => {
    // Update pagination state to reflect the new current page
    setPagination((prev) => ({
      ...prev,
      current: pagination.current,
      pageSize: pagination.pageSize,
    }));
  };

  const userData = JSON.parse(localStorage.getItem('userData'));
  const checkAccess = RoleController[userData?.role]?.edit?.find((item) => item === pathname);

  const columns = [
    {
      title: 'Full name',
      dataIndex: '',
      width: 200,
      render: (_, record) => (
        <div className="employee-info">
          <Popover
            placement="bottom"
            content={
              <div className="pop-over">
                <div className="email">
                  <span>{record?.email}</span>
                  {record?.email && (
                    <Icon icon="copy" onClick={() => handleCopyText(record.email)} />
                  )}
                </div>
                <div className="email telegram">
                  <span>{record?.telegram}</span>
                  {record?.telegram && (
                    <Icon icon="copy" onClick={() => handleCopyText(record.telegram)} />
                  )}
                </div>
              </div>
            }
          >
            <div className="employee">
              <div className="avatar">
                <img
                  src={
                    record.status === 'active'
                      ? AvatarConfirmed
                      : record.status === 'intern'
                      ? AvatarNotConfirmed
                      : AvatarArchived
                  }
                  alt=""
                />
              </div>
              <div className="personal-info">
                <h4>{record.fullname}</h4>
                <p>{record.phone_number}</p>
              </div>
              <Icon icon="arrov-down" />
            </div>
            <Icon icon="arrow-down" />
          </Popover>
        </div>
      ),
    },
    {
      title: 'Birth date',
      dataIndex: '',
      width: 110,
      render: (_, record) => <span>{moment(Number(record.birth_date)).format('DD.MM.YYYY')}</span>,
    },

    {
      title: 'Job title',
      dataIndex: '',
      render: (_, record) => (
        <div className="job">
          <span>{record.job_title.name}</span>
          <p>{record.work_experience?.name}</p>
        </div>
      ),
    },
    {
      title: 'PDF',
      dataIndex: '',
      width: 60,
      render: (_, record) => (
        <span className="file">
          <Icon icon="pdf" onClick={() => showFilesModal(record)} />
        </span>
      ),
    },
    {
      title: (
        <div className="salary-column-header">
          <span>Salary</span>
          <Icon onClick={handleShowSalary} icon={visibility} />
        </div>
      ),
      dataIndex: '',
      // width: 130,
      render: (_, record) => (
        <span className={showSalary}>
          {record.salary
            ? Number(record.salary)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ' ') +
              ' ' +
              record.currency.name
            : ''}
        </span>
      ),
    },
    {
      title: 'Address',
      dataIndex: 'address',
      width: 190,
      render: (_, record) => (
        <div className="job">
          <span>{record.address}</span>
          <p>{record.region.name}</p>
        </div>
      ),
    },
    {
      title: 'Company',
      dataIndex: '',
      render: (_, record) => <div>{record.company[0].name}</div>,
    },
    {
      title: 'Date of join',
      dataIndex: '',
      render: (_, record) => <span>{moment(Number(record.date_join)).format('DD.MM.YYYY')}</span>,
    },
    checkAccess
      ? {
          title: 'Edit',
          dataIndex: 'edit',
          width: 85,
          render: (_, record) => (
            <span className="edit">
              <Icon icon="edit" onClick={() => showEditModal(record)} />
              <Icon icon="delete" onClick={() => showDeleteConfirm(record)} />
            </span>
          ),
        }
      : {},
  ];

  return (
    <>
      <div className="employees projects_log" style={{ opacity: opacity }}>
        <div className="page-title">
          <h1 className="uppercase">{userData?.role}</h1>
          <p>Here you may see information about employees</p>
        </div>
        <div className="upper-section">
          <div className="left">
            <div className="search-btn">
              <Space direction="vertical">
                <Search
                  placeholder="Search for employees..."
                  style={{ width: 200 }}
                  onChange={(value) => setSearchValue(value.target.value)}
                />
              </Space>
            </div>
            <div className="select">
              <Space wrap>
                <Select placeholder="Job title" style={{ width: 170 }} onChange={handleTitleChange}>
                  <Select.Option value="all">All</Select.Option>
                  {jobTitles
                    ?.filter((c) => c.is_active)
                    .map((activeTitle) => (
                      <Select.Option key={activeTitle.id} value={activeTitle.id}>
                        {activeTitle.name}
                      </Select.Option>
                    ))}
                </Select>
              </Space>
              <Icon icon="arrow-down" />
            </div>
            <div className="select">
              <Space wrap>
                <Select placeholder="Company" style={{ width: 170 }} onChange={handleCompanyChange}>
                  <Select.Option value="all">All</Select.Option>
                  {company
                    ?.filter((c) => c.is_active)
                    .map((activeCompany) => (
                      <Select.Option key={activeCompany.id} value={activeCompany.id}>
                        {activeCompany.name}
                      </Select.Option>
                    ))}
                </Select>
              </Space>
              <Icon icon="arrow-down" />
            </div>
            <div className="select">
              <Space wrap>
                <Select placeholder="Status" style={{ width: 170 }} onChange={handleStatusChange}>
                  <Select.Option value="all">All</Select.Option>
                  <Select.Option value="active">Confirmed</Select.Option>
                  <Select.Option value="intern">Intern</Select.Option>
                  <Select.Option value="archived">Archived</Select.Option>
                </Select>
              </Space>
              <Icon icon="arrow-down" />
            </div>
          </div>
          {(userData?.role === 'hr' || userData?.role === 'ceo') && (
            <div className="download-btn">
              <Button size="large" type="primary" icon={<PlusOutlined />} onClick={showCreateModal}>
                Add employee
              </Button>
            </div>
          )}
        </div>
        <div className="table">
          <Table
            columns={columns}
            dataSource={employeeData?.data || []}
            loading={isEmployeeDataLoading}
            pagination={{
              current: pagination.current,
              pageSize: pagination.pageSize,
              total: employeeData?.total_elements || 0, // Total records from the backend
              showSizeChanger: true,
            }}
            onChange={handleTableChange}
            footer={() => (
              <div style={{ textAlign: 'end' }}>
                <div className="total-salary">
                  <p>Total salary: </p>
                  <span className="amount-wrapper">
                    <span className="total-salary-amount">
                      {employees.total_salary
                        ? Number(employees.total_salary)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ' ') +
                          ' ' +
                          'UZS'
                        : ''}
                    </span>
                    <span className="hide-total-salary">**************</span>
                  </span>
                </div>
              </div>
            )}
          />
        </div>
        <EmployeeModal
          isLoading={isEmployeeCreateLoading || isEmployeeUpdateLoading}
          employee={currentEmployee}
          isVisible={isModalVisible}
          onSave={handleSubmit}
          onCancel={handleCancel}
          isEditMode={isEditMode}
        />
        {
          <FileAdd
            files={employeeFiles}
            isVisible={isFileslVisible}
            onSave={handleSaveFile}
            onCancel={handleCancelFile}
            currentEmployee={employeeId}
          />
        }
      </div>
      <div className="loader" style={{ opacity: loader }}>
        <Spin
          indicator={
            <LoadingOutlined
              style={{
                fontSize: 44,
              }}
              spin
            />
          }
        />
      </div>
    </>
  );
};

export default Employees;
