import Icon from '../Icon';
import Logo from '../../Assets/images/logo.svg';
// import Notification from '../../Assets/images/notification.svg';
import AvatarNotConfirmed from '../../Assets/images/user.svg';
import UserConfirmed from '../../Assets/images/user_confirmed.svg';
import AvatarArchived from '../../Assets/images/archived_avatar.svg';
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import Muz from '../../Assets/images/muzaffar.jpg';
// import Drop from '../../Assets/images/chevron-down.svg';
// import { useNavigate, useParams } from "react-router-dom"/
// import { useEffect } from "react"
// import { Avatar, Badge, Space } from 'antd';
// import { UserOutlined } from '@ant-design/icons';
import { Popover } from 'antd';

function Navbar({ setCollapsed, collapsed }) {
  const userData = JSON.parse(localStorage.getItem('userData'));

  //     const navigate = useNavigate()
  //     const {pathname} = useParams()
  // console.log(userData);
  //     useEffect(()=> {
  //         navigate(pathname, { replace: true });
  //     }, [])

  return (
    <nav>
      <div className="nav-wrapper">
        <div className="left-menu">
          {/* <img src={Logo} alt="Logo" className="nav_logo" /> */}
          <Button
            type="text"
            icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            onClick={() => setCollapsed(!collapsed)}
            style={{
              fontSize: '16px',
              width: 64,
              height: 64,
            }}
          />
        </div>
        <div className="right-profile">
          {/* <div className="notifications">
            <img src={Notification} alt="Logo" />
          </div> */}
          <div className="profile">
            <div className="avatar">
              {userData?.telegram === '@Muzaffarxon_Dev' ? (
                <div className="avatar_wrapper">
                  <Popover
                    placement="bottom"
                    title={userData?.fullname}
                    content={<img className="my_image_big" src={Muz} alt="Logo" />}
                  >
                    <img className="my_image" src={Muz} alt="Logo" />
                  </Popover>
                </div>
              ) : (
                <img
                  src={
                    userData?.status === 'active'
                      ? UserConfirmed
                      : userData?.status === 'intern'
                      ? AvatarNotConfirmed
                      : AvatarArchived
                  }
                  alt=""
                />
              )}
            </div>
            <div className="info">
              <h3 className={userData?.telegram === '@shakhzod9910' ? 'pro_account' : ''}>
                {userData?.fullname}
              </h3>
              <span>{userData?.job_title?.name}</span>
              {userData?.is_lead === true ? (
                <span
                  style={{
                    marginLeft: '5px',
                    backgroundColor: '#32B4FD',
                    color: '#fff',
                  }}
                >
                  LEAD
                </span>
              ) : null}
            </div>
          </div>
          {/* <div className="drop-down">
            <img src={Drop} alt="Logo" />
          </div> */}
        </div>
      </div>
      <div className="mobile_nav_wrapper">
        <div className="left-menu">
          <img src={Logo} alt="Logo" />
          <Icon icon="hamburger" onClick={() => setCollapsed(!collapsed)} />
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
